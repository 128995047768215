<template>
   <div class="row">
      <div class="col-12" v-for="(compra, index) in pesquisa.resultado" :key="index">
         <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
            <div class="card-body p-3 pe-1">
               <div class="row m-0 font-12">
                  <div class="col-md-8 px-1 align-self-center">
                     <div class="row m-0">
                        <div class="col-12 p-0 text-center text-md-start mb-2">
                           <div class="limitador font-16 weight-600">{{ formatDateDay(compra.dataCompra) }} {{ formatDateTime(compra.dataCompra) }}</div>
                        </div>
                        <div class="col p-0 d-none d-md-inline">
                           <div class="limitador">
                              <i class="far fa-box color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> {{ $t('admCompras.pacotes') }}:</strong> 
                              {{ compra.pacotes }}
                           </div>
                        </div>
                        <div class="col-6 p-0 d-none d-md-inline" v-if="compra.urlPagamento">
                           <a class="limitador" :href="compra.urlPagamento" target="_blank">
                              <i class="far fa-link color-theme font-10 me-1"></i><strong class="me-1 d-none d-xl-inline text-secondary"> {{ $t('admCompras.pagamento') }}:</strong> 
                              {{ $t('admCompras.novaGuia') }} <i class="far fa-external-link-alt font-9 ms-1"></i>
                           </a>
                        </div>
                     </div>
                  </div>

                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-14">
                     <div class="limitador-2">
                        <strong title="Status" :class="compra.status == 'Finalizado' ? 'text-success' : compra.status == 'Cancelado' ? 'text-danger' : 'text-warning'">{{ compra.status }}</strong>
                     </div>
                  </div>

                  <div class="col-6 col-md-2 px-1 align-self-center text-center font-15">
                     <div class="limitador-2">
                        <small class="font-14 color-theme">R$</small>
                        <strong title="Valor"> {{ compra.valorTotal == null ? '-' : parseFloat(compra.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</strong>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="col-12 text-center my-5" v-if="pesquisa.resultado.length == 0">{{ $t('admCompras.semResultados') }}</div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Compras',
	data: function () {
		return {
         pesquisa: {'nome': '', 'resultado': []},
         valorTotal: 0
		}
	},
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
   methods: {
      searchHistorico : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'pagamento/historicoComprasUsuario',
            params: {
               idUsuario: this.dadosUsuario.id
            }
         }).then(response => {
				this.pesquisa.resultado = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      formatDateDay(data) {
         const { dayOfMonth, month, year } = data;
         const formattedDayOfMonth = String(dayOfMonth).padStart(2, '0');
         const formattedMonth = this.getMonthNumber(month);
         const formattedYear = year;
         const formattedDate = `${formattedDayOfMonth}/${formattedMonth}/${formattedYear}`;

         return formattedDate;
      },
      formatDateTime(data) {
			const { hour, minute } = data;
			const formattedHour = String(hour).padStart(2, '0');
			const formattedMinute = String(minute).padStart(2, '0');
			const formattedTime = `${formattedHour}:${formattedMinute}`;

			return formattedTime;
		},
		getMonthNumber(month) {
			const months = {
            JANUARY: '01',
            FEBRUARY: '02',
            MARCH: '03',
            APRIL: '04',
            MAY: '05',
            JUNE: '06',
            JULY: '07',
            AUGUST: '08',
            SEPTEMBER: '09',
            OCTOBER: '10',
            NOVEMBER: '11',
            DECEMBER: '12'
			};

			return months[month];
		}
   },
   mounted() {
      this.searchHistorico()
   }
}

</script>